import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, firestoreIncrement, firestoreDeleteField } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import localizeFilter from '@/filters/localize.filter'
import useNotifications from '@/composables/useNotifications'
import router from '../router'

export default {
  state: {
    banks: {},
    banksReset: 0,
    banksFilters: {
      searchStr: ''
    },
    banksShow: {
      add: false,
      edit: null,
      banksList: null,
      deleteBank: null
    }
  },
  mutations: {
    setBank(state, { bankId, data }) {
      if (bankId && data) {
        state.banks[bankId] = data
      }
    },
    updateBank(state, { bankId, data }) {
      if (data && bankId) {
        if (!state.banks[bankId]) {
          state.banks[bankId] = {}
        }

        for (const key of Object.keys(data)) {
          state.banks[bankId][key] = data[key]
        }
      }
    },
    deleteBankFromStore(state, bankId) {
      if (bankId && state.banks[bankId]) {
        delete state.banks[bankId]
      }
    },
    resetBanks(state) {
      state.banksReset = Date.now()
    },
    setBanksFiltersSearchStr(state, value) {
      state.banksFilters.searchStr = value
    },
    setBanksShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.banksShow[field] = show[field]
        }
      }
    },
    clearBanks(state) {
      state.banks = {}
      state.banksReset = 0
    },
    clearBanksFilters(state) {
      state.banksFilters = {
        searchStr: ''
      }
    },
    clearBanksShow(state) {
      state.banksShow = {
        add: false,
        edit: null,
        banksList: null,
        deleteBank: null
      }
    },
    clearInfo(state) {
      state.banks = {}
      state.banksFilters = {
        searchStr: ''
      }
      state.banksShow = {
        add: false,
        edit: null,
        banksList: null,
        deleteBank: null
      }
    }
  },
  actions: {
    async fetchBanks({ commit, dispatch, getters }) {
      if (getters.lessonStep) { return }
      if (!getters.getLimitNumber('maxBanks')) { return }

      const t = tracePerformance('fetchBanks')
      t.start()

      try {
        const request = {
          wheres: [
            ['owner', '==', getUid()]
          ]
        }

        const banks = await getFirestoreDocs({
          collectionName: 'banks',
          ...request
        })

        if (banks) {
          for (const bank of banks) {
            await dispatch('bankAdded', { bankId: bank.id, data: bank.data })
          }
        }

        await commit('setLoadedAll', { field: 'banks', value: true })

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchBanks', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchBank({ commit, dispatch, getters }, bankId) {
      if (getters.lessonStep) { return }
      if (!getters.getLimitNumber('maxBanks')) { return }

      if (!bankId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('fetchBank')
      t.start()

      try {
        const data = await getFirestoreDoc('banks', bankId)

        if (data) {
          await dispatch('bankAdded', { bankId, data })
          return true
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchBank', params: { bankId } })
        return false
      } finally {
        t.stop()
      }
    },
    async showCreateBankClicked({ commit, dispatch, getters }, { from = null }) {
      if (!getters.getLimitNumber('maxBanks') || !getters.getAvailableLimitNumber('banksReset', 'banks', 'maxBanks')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      await dispatch('setCloseAllMenus')
      setTimeout(() => {
        commit('setBanksShowField', { add: true })
      }, 0)

      if (from) { logAnalyticsEvent('showCreateBankClicked', { from }) }
    },
    async createBank({ commit, dispatch, getters }, { name }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()
      let toastId

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.getAvailableLimitNumber('banksReset', 'banks', 'maxBanks')) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      if (!name || !name.trim()) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('createBank')
      t.start()

      const syncTimestamp = new Date()

      const data = {
        name: name.trim(),
        timestamp: syncTimestamp,
        owner: getUid()
      }

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const bankId = createId('banks')

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'banks',
            id: bankId,
            data,
            updateStats: 1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('bankAdded', { bankId, data, increaseTotalNumber: 1 })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        return bankId
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createBank', params: { name }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async bankAdded({ commit }, { bankId, data, increaseTotalNumber = 0 }) {
      if (!bankId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('setBank', { bankId, data })

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'banks', number: increaseTotalNumber })
      }

      commit('resetBanks')
      commit('resetAccounts')
      commit('resetTransactions')
    },
    async editBank({ commit, dispatch, getters }, { bankId, name }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!bankId || !name) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxBanks') || (+getters.userStats('banks') > +getters.getLimitNumber('maxBanks'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const { toastify } = useNotifications()
      let toastId

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('editBank')
      t.start()

      const syncTimestamp = new Date()

      try {
        const data = {
          name: name.trim()
        }

        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'banks',
            id: bankId,
            data
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('bankEdited', { bankId, data })
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'editBank', params: { bankId, name }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async bankEdited({ commit }, { bankId, data }) {
      if (!bankId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('updateBank', { bankId, data })

      commit('resetBanks')
      commit('resetAccounts')
      commit('resetTransactions')
    },
    async deleteBank({ commit, dispatch, getters }, { bankId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!bankId || !getters.banks[bankId]) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()
      let toastId

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteBank')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('DeletingBank') + '...', { timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'banks',
            id: bankId,
            updateStats: -1
          }
        ]

        const accountsToChange = []

        const request = {
          wheres: [
            ['owner', '==', getUid()],
            ['bankId', '==', bankId]
          ]
        }

        const accounts = await getFirestoreDocs({
          collectionName: 'accounts',
          ...request
        })

        if (accounts) {
          for (const account of accounts) {
            if (!accountsToChange.includes(account.id)) { accountsToChange.push(account.id) }
          }
        }

        for (const accountId of accountsToChange) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: accountId,
            data: {
              bankId: firestoreDeleteField
            }
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('bankDeleted', bankId)
        toastify.replace(toastId, localizeFilter('BankDeleted'), 'success')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteBank', params: { bankId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteAllBanks({ commit, dispatch, getters }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteAllBanks')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const banksToDelete = []

        const banksRequest = {
          wheres: [
            ['owner', '==', getUid()],
          ]
        }

        const banks = await getFirestoreDocs({
          collectionName: 'banks',
          ...banksRequest
        })

        if (banks) {
          for (const bank of banks) {
            if (!banksToDelete.includes(bank.id)) { banksToDelete.push(bank.id) }
          }
        }

        if (!banksToDelete.length) {
          toastify.replace(toastId, localizeFilter('Deleted'), 'success')
          return
        }

        const batchData = []

        for (const bankId of banksToDelete) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'banks',
            id: bankId
          })
        }

        if (banksToDelete.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              banks: firestoreIncrement(banksToDelete.length * -1)
            },
            noLogs: true
          })
        }

        const accountsToChange = []

        const request = {
          wheres: [
            ['owner', '==', getUid()],
            ['bankId', '!=', false]
          ]
        }

        const accounts = await getFirestoreDocs({
          collectionName: 'accounts',
          ...request
        })

        if (accounts) {
          for (const account of accounts) {
            if (!accountsToChange.includes(account.id)) { accountsToChange.push(account.id) }
          }
        }

        for (const accountId of accountsToChange) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'accounts',
            id: accountId,
            data: {
              bankId: firestoreDeleteField
            }
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        for (const bankId of banksToDelete) {
          await dispatch('bankDeleted', bankId)
        }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('allBanksDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAllBanks', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async bankDeleted({ commit, getters }, bankId) {
      if (!bankId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      let accountsIds = Object.keys(getters.accounts)

      accountsIds = accountsIds.filter(accountId => {
        return getters.accounts[accountId].bankId === bankId
      })

      for (const accountId of accountsIds) {
        await commit('updateAccount', {
          accountId,
          data: {
            bankId: null
          }
        })
      }

      await commit('deleteBankFromStore', bankId)
      await commit('increaseTotalNumberOf', { field: 'banks', number: -1 })

      commit('resetAccounts')
      commit('resetBanks')
      commit('resetTransactions')
    },
    openBanksClicked({ commit, getters }) {
      if (!getters.getLimitNumber('maxBanks')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      router.push({ name: 'Banks' }).catch(() => { })
    }
  },
  getters: {
    banks: s => s.banks,
    filteredBanks: (s, getters) => {
      if (getters.banksReset) {
        //
      }

      let banksIds = Object.keys(s.banks)

      if (banksIds.length) {
        banksIds = banksIds.filter(bankId => {
          const bank = s.banks[bankId]

          return (
            (!s.banksFilters.searchStr || (
              (bank && bank.name.toLowerCase().includes(s.banksFilters.searchStr.toLowerCase()))
            ))
          )
        })
      }

      if (banksIds.length > 1) {
        banksIds.sort((a, b) => {
          const nameA = s.banks[a].name || a
          const nameB = s.banks[b].name || b

          if (nameA < nameB) { return -1 }
          if (nameA > nameB) { return 1 }
          return 0
        })
      }

      return banksIds
    },
    banksReset: s => s.banksReset,
    banksFilters: s => s.banksFilters,
    banksFiltered: s => {
      if (s.banksFilters.searchStr) { return true }
      return false
    },
    banksShow: s => s.banksShow
  }
}