import { createBatch } from '@/firebase/firestore'
import { uploadFileStringToStorage, deleteImageFromStorage } from '@/firebase/storage'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { countFileSize } from '@/helpers/fileinfo'
import { getUid } from '@/firebase/auth'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import { checkValues } from '@/helpers/check-values'

export default {
  actions: {
    async uploadUserPic({ commit, dispatch }, { dataUrl = null, logEvent }) {
      if (!dataUrl) {
        commit('setError', localizeFilter('Error'))
        return
      }

      const sizeInBytes = await countFileSize(dataUrl)
      if (+sizeInBytes > checkValues('userPic', 'bytes')) {
        commit('setError', localizeFilter('CorrectFileSize') + ' ' + Math.floor(checkValues('userPic', 'bytes') / 1024 / 1024) + ' MB')
        return
      }

      const { toastify } = useNotifications()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const link = await uploadFileStringToStorage('userpic', null, dataUrl)
        if (!link) { return }

        const syncTimestamp = new Date()

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userProfile',
            id: getUid(),
            data: {
              userPic: link
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await commit('setUserPic', link)

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        if (logEvent) { logAnalyticsEvent(logEvent) }
        return link
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'uploadUserPic', params: { dataUrl }, toastId })
        return
      } finally {
        commit('setProcessing', false)
      }
    },
    async deleteUserPics({ commit, dispatch }) {
      const { toastify } = useNotifications()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        await deleteImageFromStorage('userpic', null)

        const syncTimestamp = new Date()

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userProfile',
            id: getUid(),
            data: {
              userPic: null
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        commit('setUserPic', null)
        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('userPicDeleted')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteUserPics', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
      }
    },
    async uploadReceiptPic({ commit, dispatch, getters }, { dataUrl = null, transactionId = null, fileExtension = null }) {
      if (!getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!dataUrl || !transactionId || !fileExtension) {
        commit('setError', localizeFilter('Error'))
        return
      }

      const sizeInBytes = await countFileSize()
      if (+sizeInBytes > checkValues('receipt', 'bytes')) {
        commit('setError', localizeFilter('CorrectFileSize') + ' ' + Math.floor(checkValues('receipt', 'bytes') / 1024 / 1024) + ' MB')
        return
      }

      const t = tracePerformance('setAccountActive')
      t.start()

      let toastId

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)

        toastId = toastify.warning(localizeFilter('Saving') + '...', { timeout: null })

        const link = await uploadFileStringToStorage('receipts', transactionId, dataUrl)
        if (!link) {
          toastify.remove(toastId)
          commit('setError', localizeFilter('Error'))
          return
        }

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'transactions',
            id: transactionId,
            data: {
              receiptId: transactionId
            },
            logDataInfo: {
              receiptId: transactionId,
              receiptFileLink: link
            }
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await commit('updateTransactionInStore', {
          transactionId,
          data: {
            receiptId: transactionId,
            receiptFileLink: link
          }
        })

        toastify.replace(toastId, localizeFilter('Saved'), 'success')
        logAnalyticsEvent('receiptSaved' + fileExtension)

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'uploadReceiptPic', params: { dataUrl, transactionId, fileExtension } })
        return
      } finally {
        commit('setProcessing', false)
      }
    }
  }
}