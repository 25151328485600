import { createBatch, createId, getFirestoreDoc, getFirestoreDocs, firestoreIncrement } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { logAnalyticsEvent } from '@/firebase/analytics'
import { getUid } from '@/firebase/auth'
import localizeFilter from '@/filters/localize.filter'
import useNotifications from '@/composables/useNotifications'
import router from '../router'
import Currencies from '@/libraries/currencies'

export default {
  state: {
    cards: {},
    cardsReset: 0,
    showArchivedCards: false,
    cardsShow: {
      add: false,
      delete: null,
      filters: false
    },
    cardsFilters: {
      section: null,
      bankId: null,
      accountId: null,
      currencyCode: null,
      expiringCards: false,
      expiredCards: false,
      onlyDefault: false,
      searchStr: ''
    },
    cardsSort: {
      field: 'Card',
      direction: 'asc'
    }
  },
  mutations: {
    setCard(state, { cardId, data }) {
      if (cardId && data) {
        state.cards[cardId] = data
      }
    },
    updateCard(state, { cardId, data }) {
      if (cardId && data) {
        if (!state.cards[cardId]) {
          state.cards[cardId] = {}
        }

        for (const key of Object.keys(data)) {
          state.cards[cardId][key] = data[key]
        }
      }
    },
    deleteCardFromStore(state, cardId) {
      if (state.cards[cardId]) {
        delete state.cards[cardId]
      }
    },
    resetCards(state) {
      state.cardsReset = Date.now()
    },
    setAccountCardsInactive(state, accountId) {
      let cardsIds = Object.keys(state.cards)
      if (cardsIds.length) {
        cardsIds = cardsIds.filter(cardId => {
          return (state.cards[cardId].accountId === accountId && state.cards[cardId].active === true)
        })
      }

      for (const cardId of cardsIds) {
        state.cards[cardId].active = false
      }
    },
    deleteAllCardsOfAccountFromStore(state, accountId) {
      let cardsIds = Object.keys(state.cards)
      if (cardsIds.length) {
        cardsIds = cardsIds.filter(cardId => {
          return (state.cards[cardId].accountId === accountId)
        })
      }

      for (const cardId of cardsIds) {
        delete state.cards[cardId]
      }
    },
    setCardsShowField(state, show) {
      if (show && Object.keys(show).length) {
        for (const field of Object.keys(show)) {
          state.cardsShow[field] = show[field]
        }
      }
    },
    setCardsFiltersSection(state, section) {
      state.cardsFilters.section = section
    },
    setCardsFiltersOnlyDefault(state, value) {
      state.cardsFilters.onlyDefault = value
    },
    setCardsShowArchived(state, value) {
      state.showArchivedCards = value
    },
    setCardsSortField(state, field) {
      state.cardsSort.field = field
    },
    setCardsSortDirection(state, direction) {
      state.cardsSort.direction = direction
    },
    setCardsFilters(state, filters) {
      state.cardsFilters = filters
    },
    setCardsFiltersSearchStr(state, value) {
      state.cardsFilters.searchStr = value
    },
    clearCardsOneFilter(state, filter) {
      if (filter) {
        const filters = state.cardsFilters

        if (filter === 'Account') {
          filters.bankId = null
          filters.accountId = null
        } else if (filter === 'Currency') {
          filters.currencyCode = null
        } else if (filter === 'Cards') {
          filters.expiringCards = false
          filters.expiredCards = false
        }

        state.cardsFilters = filters
      }
    },
    clearCards(state) {
      state.cards = {}
      state.cardsReset = 0
    },
    clearCardsFilters(state) {
      state.cardsFilters = {
        section: null,
        bankId: null,
        accountId: null,
        currencyCode: null,
        expiringCards: false,
        expiredCards: false,
        onlyDefault: false,
        searchStr: ''
      }
    },
    clearCardsSort(state) {
      state.cardsSort = {
        field: 'Card',
        direction: 'asc'
      }
    },
    clearCardsShow(state) {
      state.cardsShow = {
        add: false,
        delete: null,
        filters: false
      }
    },
    clearInfo(state) {
      state.cards = {}
      state.cardsReset = 0
      state.showArchivedCards = false
      state.cardsShow = {
        add: false,
        delete: null,
        filters: false
      }
      state.cardsFilters = {
        section: null,
        bankId: null,
        accountId: null,
        currencyCode: null,
        expiringCards: false,
        expiredCards: false,
        onlyDefault: false,
        searchStr: ''
      }
      state.cardsSort = {
        field: 'Card',
        direction: 'asc'
      }
    }
  },
  actions: {
    async fetchCards({ commit, dispatch, getters }, { active, accountId }) {
      if (getters.lessonStep) { return }
      if (!getters.getLimitNumber('maxCards')) { return }
      if (active === true && getters.loadedAll('cardsActive')) { return }
      if (active === false && getters.loadedAll('cardsArchived')) { return }

      const t = tracePerformance('fetchCards')
      t.start()

      try {
        const request = {
          wheres: [
            ['active', '==', active],
            ['owner', '==', getUid()]
          ]
        }

        if (accountId) { request.wheres.push(['accountId', '==', accountId]) }

        const cards = await getFirestoreDocs({
          collectionName: 'cards',
          ...request
        })

        if (cards) {
          for (const card of cards) {
            await dispatch('cardAdded', { cardId: card.id, data: card.data })
          }

          if (!accountId) {
            if (active === true) {
              await commit('setLoadedAll', { field: 'cardsActive', value: true })
            } else if (active === false) {
              await commit('setLoadedAll', { field: 'cardsArchived', value: true })
            }
          }
        }

        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchCards', params: { active, accountId } })
        return false
      } finally {
        t.stop()
      }
    },
    async fetchCard({ dispatch, getters }, cardId) {
      if (getters.lessonStep) { return }
      if (!getters.getLimitNumber('maxCards')) { return }

      const t = tracePerformance('fetchCard')
      t.start()

      try {
        const data = await getFirestoreDoc('cards', cardId)

        if (data) {
          await dispatch('cardAdded', { cardId, data })
          return true
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'fetchCard', params: { cardId } })
        return false
      } finally {
        t.stop()
      }
    },
    async createCard({ commit, dispatch, getters }, { accountId, name, cardExpire }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()
      let toastId

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!getters.getLimitNumber('maxCards') || (+getters.userStats('cards') >= +getters.getLimitNumber('maxCards'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      if (!accountId || !name || !name.trim()) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const t = tracePerformance('createCard')
      t.start()

      const syncTimestamp = new Date()

      const data = {
        accountId,
        active: true,
        cardExpire: cardExpire || null,
        name: name.trim(),
        owner: getUid(),
        timestamp: syncTimestamp
      }

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('SavingCard') + '...', { timeout: null })

        const cardId = createId('cards')

        const batchArray = await createBatch([
          {
            timestamp: syncTimestamp,
            type: 'set',
            place: 'cards',
            id: cardId,
            data,
            updateStats: 1
          }
        ])

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('cardAdded', { cardId, data, increaseTotalNumber: 1 })

        toastify.replace(toastId, localizeFilter('CardCreated'), 'success')
        toastify.success(localizeFilter('CardAddedToAccount'))
        return cardId
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'createCard', params: { data }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async cardAdded({ commit }, { cardId, data, increaseTotalNumber = 0 }) {
      if (!cardId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('setCard', { cardId, data })

      if (increaseTotalNumber) {
        await commit('increaseTotalNumberOf', { field: 'cards', number: increaseTotalNumber })
        commit('resetAccounts')
      }

      commit('resetCards')
      commit('resetTransactions')
    },
    async editCard({ commit, dispatch, getters }, { cardId, name, cardExpire, active }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()
      let toastId

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      if (!cardId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      if (!getters.getLimitNumber('maxCards') || (+getters.userStats('cards') > +getters.getLimitNumber('maxCards'))) {
        commit('setAppShowField', { payWall: true })
        return false
      }

      const card = getters.cards[cardId]

      const data = {}
      if (name && name !== card.name) { data.name = name }
      if (active !== undefined && active !== card.active) { data.active = active }
      if (cardExpire !== undefined && +cardExpire !== +card.cardExpire) { data.cardExpire = cardExpire }

      if (!Object.keys(data).length) { return true }

      const t = tracePerformance('editCard')
      t.start()

      const syncTimestamp = new Date()

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('SavingCard') + '...', { timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'update',
            place: 'cards',
            id: cardId,
            data,
          }
        ]

        const newDefaultAccountData = {
          accountId: card.accountId,
          cardId: null
        }

        if (active === false && getters.defaultAccount.cardId === cardId) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userDefaultAccount',
            id: getUid(),
            data: newDefaultAccountData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        await dispatch('cardEdited', { cardId, data })

        if (active === false && getters.defaultAccount.cardId === cardId) {
          await dispatch('defaultAccountFetched', newDefaultAccountData)
        }

        toastify.replace(toastId, localizeFilter('CardSaved'), 'success')
        return true
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'editCard', params: { cardId, name, cardExpire, active }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async cardEdited({ commit }, { cardId, data }) {
      if (!cardId || !data) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('updateCard', { cardId, data })
      commit('resetAccounts')
      commit('resetCards')
      commit('resetTransactions')
    },
    async deleteCard({ commit, dispatch, getters }, { cardId = null }) {
      if (getters.lessonStep || !getters.online) { return }

      if (!cardId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteCard')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('DeletingCard') + '...', { timeout: null })

        const batchData = [
          {
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'cards',
            id: cardId,
            updateStats: -1
          }
        ]

        const newDefaultAccountData = {
          accountId: getters.defaultAccount.accountId,
          cardId: null
        }

        if (getters.defaultAccount.accountId && getters.defaultAccount.cardId && getters.defaultAccount.cardId === cardId) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userDefaultAccount',
            id: getUid(),
            data: newDefaultAccountData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        if (getters.defaultAccount.accountId && getters.defaultAccount.cardId && getters.defaultAccount.cardId === cardId) {
          await dispatch('defaultAccountFetched', newDefaultAccountData)
        }

        await dispatch('cardDeleted', cardId)
        toastify.replace(toastId, localizeFilter('CardDeleted'), 'success')
        logAnalyticsEvent('cardDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteCard', params: { cardId }, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async deleteAllCards({ commit, dispatch, getters }) {
      if (getters.lessonStep || !getters.online) { return }

      const { toastify } = useNotifications()

      if (getters.processing) {
        toastify.error(localizeFilter('Wait') + '...')
        return
      }

      const t = tracePerformance('deleteAllCards')
      t.start()

      const syncTimestamp = new Date()
      let toastId

      try {
        commit('setProcessing', true)
        toastId = toastify.warning(localizeFilter('Deleting') + '...', { timeout: null })

        const cardsToDelete = []

        const request = {
          wheres: [['owner', '==', getUid()]]
        }

        const cards = await getFirestoreDocs({
          collectionName: 'cards',
          ...request
        })

        if (cards) {
          for (const card of cards) {
            if (!cardsToDelete.includes(card.id)) { cardsToDelete.push(card.id) }
          }
        }

        if (!cardsToDelete.length) {
          toastify.replace(toastId, localizeFilter('Deleted'), 'success')
          return
        }

        const batchData = []

        for (const cardId of cardsToDelete) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'delete',
            place: 'cards',
            id: cardId
          })
        }

        if (cardsToDelete.length) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'update',
            place: 'userStats',
            id: getUid(),
            data: {
              cards: firestoreIncrement(cardsToDelete.length * -1)
            },
            noLogs: true
          })
        }

        const newDefaultAccountData = {
          accountId: getters.defaultAccount.accountId,
          cardId: null
        }

        if (getters.defaultAccount.accountId && getters.defaultAccount.cardId && cardsToDelete.includes(getters.defaultAccount.cardId)) {
          batchData.push({
            timestamp: syncTimestamp,
            type: 'set',
            place: 'userDefaultAccount',
            id: getUid(),
            data: newDefaultAccountData
          })
        }

        const batchArray = await createBatch(batchData)

        await dispatch('subscribeToLogs', syncTimestamp)

        for (const batch of batchArray) { await batch.commit() }

        if (getters.defaultAccount.accountId && getters.defaultAccount.cardId && cardsToDelete.includes(getters.defaultAccount.cardId)) {
          await dispatch('defaultAccountFetched', newDefaultAccountData)
        }

        for (const cardId of cardsToDelete) {
          await dispatch('cardDeleted', cardId)
        }

        toastify.replace(toastId, localizeFilter('Deleted'), 'success')
        logAnalyticsEvent('allCardsDeleted')
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'deleteAllCards', params: {}, toastId })
        return false
      } finally {
        commit('setProcessing', false)
        t.stop()
      }
    },
    async cardDeleted({ commit }, cardId) {
      if (!cardId) {
        commit('setError', localizeFilter('Error'))
        return false
      }

      await commit('deleteCardFromStore', cardId)
      await commit('increaseTotalNumberOf', { field: 'cards', number: -1 })

      commit('resetAccounts')
      commit('resetCards')
      commit('resetTransactions')
    },
    openCardsClicked({ commit, getters }) {
      if (!getters.getLimitNumber('maxCards')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      router.push({ name: 'Cards' }).catch(() => { })
    },
    async showCreateCardClicked({ commit, dispatch, getters }, { from = null }) {
      if (!getters.getLimitNumber('maxCards') || !getters.getAvailableLimitNumber('cardsReset', 'cards', 'maxCards')) {
        commit('setAppShowField', { payWall: true })
        return
      }

      await dispatch('setCloseAllMenus')
      setTimeout(() => {
        commit('setCardsShowField', { add: true })
      }, 0)

      if (from) { logAnalyticsEvent('showCreateCardClicked', { from }) }
    },
    showFilterCardsButtonClicked({ commit }, { section, value }) {
      commit('setCardsFiltersSection', section)
      commit('setCardsShowField', { filters: value })
    },
    showStarCardClicked({ commit }, { show, from }) {
      commit('setCardsFiltersOnlyDefault', show)
      logAnalyticsEvent('showStarCardClicked', { from })
    },
    showArchivedCardsClicked({ commit, dispatch, getters }) {
      const shown = getters.showArchivedCards
      commit('setCardsShowArchived', !shown)
      if (!shown) { dispatch('fetchCards', { active: false }) }
    },
    clearCardsAllFilters({ commit }) {
      commit('setCardsFiltersSection', null)
      commit('clearCardsFilters')
      commit('setCardsShowField', { filters: false })
    },
    setCardsFiltersAction({ commit }, filters = {}) {
      commit('setCardsFilters', filters)
      logAnalyticsEvent('setCardsFiltersAction', { ...filters })
    }
  },
  getters: {
    cards: s => s.cards,
    filteredCards: (s, getters) => {
      if (getters.cardsReset) {
        //
      }

      let cardsIds = Object.keys(s.cards)

      if (cardsIds.length) {
        const currencyNameContainsSearchStr = (currencyObj) => {
          if (!currencyObj) { return false }

          for (const languageId of Object.keys(currencyObj)) {
            if (currencyObj[languageId] && currencyObj[languageId].toLowerCase().includes(s.cardsFilters.searchStr.toLowerCase())) { return true }
          }
          return false
        }

        cardsIds = cardsIds.filter(cardId => {
          const card = s.cards[cardId]

          return (
            (!s.cardsFilters.onlyDefault || (getters.defaultAccount && getters.defaultAccount.cardId && s.cards[getters.defaultAccount.cardId] && cardId === getters.defaultAccount.cardId)) &&
            (s.showArchivedCards || card.active) &&
            (s.cardsFilters.bankId === null || (s.cardsFilters.bankId === 'nobank' && card.accountId && getters.accounts[card.accountId] && !getters.accounts[card.accountId].bankId) || (s.cardsFilters.bankId === getters.accounts[card.accountId].bankId)) &&
            (!s.cardsFilters.accountId || card.accountId === s.cardsFilters.accountId) &&
            (!s.cardsFilters.currencyCode || (card.accountId && getters.accounts[card.accountId] && getters.accounts[card.accountId].currencyCode === s.cardsFilters.currencyCode)) &&
            (!s.cardsFilters.expiringCards || (card.cardExpire && (+card.cardExpire > Date.now()) && (+card.cardExpire <= +new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)))) &&
            (!s.cardsFilters.expiredCards || (card.cardExpire && +card.cardExpire <= Date.now())) &&
            (!s.cardsFilters.searchStr || (
              (card.name.toLowerCase().includes(s.cardsFilters.searchStr.toLowerCase()))
              || (card.accountId && getters.accounts[card.accountId] && getters.accounts[card.accountId].bankId && getters.banks[getters.accounts[card.accountId].bankId] && getters.banks[getters.accounts[card.accountId].bankId].name && getters.banks[getters.accounts[card.accountId].bankId].name.toLowerCase().includes(s.cardsFilters.searchStr.toLowerCase()))
              || (
                (card.accountId && getters.accounts[card.accountId] && getters.accounts[card.accountId].currencyCode)
                && (
                  getters.accounts[card.accountId].currencyCode.toLowerCase().includes(s.cardsFilters.searchStr.toLowerCase())
                  || (Currencies[getters.accounts[card.accountId].currencyCode] && Currencies[getters.accounts[card.accountId].currencyCode].symbolUnicode && Currencies[getters.accounts[card.accountId].currencyCode].symbolUnicode === s.cardsFilters.searchStr)
                  || (Currencies[getters.accounts[card.accountId].currencyCode] && Currencies[getters.accounts[card.accountId].currencyCode].locales && currencyNameContainsSearchStr(Currencies[getters.accounts[card.accountId].currencyCode].locales))
                )
              )
            ))
          )
        })
      }

      if (cardsIds.length > 1) {
        const sortParameter = s.cardsSort.field
        const sortType = s.cardsSort.direction

        if (sortParameter === 'Card' || !sortParameter) {
          cardsIds.sort((a, b) => {
            const cardA = s.cards[a]
            const cardB = s.cards[b]

            let nameA = cardA.name ? cardA.name.toLowerCase() : ''
            let nameB = cardB.name ? cardB.name.toLowerCase() : ''

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        } else if (sortParameter === 'Account' || !sortParameter) {
          cardsIds.sort((a, b) => {
            const accountA = getters.accounts[a.accountId] || {}
            const accountB = getters.accounts[b.accountId] || {}

            let nameA = accountA.name ? accountA.name.toLowerCase() : ''
            let nameB = accountB.name ? accountB.name.toLowerCase() : ''

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        } else if (sortParameter === 'CurrencyCode') {
          cardsIds.sort((a, b) => {
            const accountA = getters.accounts[a.accountId] || {}
            const accountB = getters.accounts[b.accountId] || {}

            let currencyCodeA = accountA.currencyCode ? accountA.currencyCode : ''
            let currencyCodeB = accountB.currencyCode ? accountB.currencyCode : ''

            if (currencyCodeA === currencyCodeB) {
              if (accountA.name === accountB.name) {
                if (a < b) { return -1 }
                if (a > b) { return 1 }
                return 0
              } else {
                if (accountA.name < accountB.name) { return -1 }
                if (accountA.name > accountB.name) { return 1 }
                return 0
              }
            } else {
              if (currencyCodeA < currencyCodeB) { return -1 }
              if (currencyCodeA > currencyCodeB) { return 1 }
              return 0
            }
          })
        } else {
          cardsIds.sort((a, b) => {
            const cardA = s.cards[a]
            const cardB = s.cards[b]

            let nameA = cardA.name ? cardA.name.toLowerCase() : ''
            let nameB = cardB.name ? cardB.name.toLowerCase() : ''

            if (nameA === nameB) {
              if (a < b) { return -1 }
              if (a > b) { return 1 }
              return 0
            } else {
              if (nameA < nameB) { return -1 }
              if (nameA > nameB) { return 1 }
              return 0
            }
          })
        }

        if (sortType === 'desc') { cardsIds.reverse() }
      }

      return cardsIds
    },
    cardsFilters: s => s.cardsFilters,
    cardsFiltered: s => {
      if (s.cardsFilters.bankId ||
        s.cardsFilters.accountId ||
        s.cardsFilters.currencyCode ||
        s.cardsFilters.expiringCards ||
        s.cardsFilters.expiredCards ||
        s.cardsFilters.onlyDefault ||
        s.cardsFilters.searchStr
      ) {
        return true
      }
      return false
    },
    cardsReset: s => s.cardsReset,
    cardsSort: s => s.cardsSort,
    showArchivedCards: s => s.showArchivedCards,
    cardsExpire: (s, getters) => ({ expiring, expired }) => {
      if (getters.cardsReset) {
        //
      }

      if (!getters.getLimitNumber('maxCards')) { return [] }

      const accounts = []

      const nextMonthStart = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)

      for (const cardId in s.cards) {
        const card = s.cards[cardId]

        if (card.active && card.cardExpire && card.accountId && !accounts.includes(card.accountId)) {
          if (expired) {
            if ((+card.cardExpire <= Date.now())) {
              accounts.push(card.accountId)
            }
          } else if (expiring) {
            if ((+card.cardExpire > Date.now()) && (+card.cardExpire <= +nextMonthStart)) {
              accounts.push(card.accountId)
            }
          }
        }
      }

      return accounts
    },
    cardsFilteredBySections: (s, getters) => {
      return {
        'Account': !!(getters.cardsFilters.bankId || getters.cardsFilters.accountId),
        'Currency': !!(getters.cardsFilters.currencyCode),
        'Cards': !!(getters.cardsFilters.expiringCards || getters.cardsFilters.expiredCards),
      }
    },
    cardsShow: s => s.cardsShow,
    shownCardsData: (s, getters) => {
      if (getters.cardsReset || getters.accountsReset) {
        //
      }

      const answer = {
        accounts: [],
        showNoBank: false,
        currencies: []
      }

      const allCards = Object.keys(s.cards)

      if (allCards.length && getters.getLimitNumber('maxCards')) {
        for (const cardId of allCards) {
          const card = s.cards[cardId]

          if (card.accountId && getters.accounts[card.accountId]) {
            if (!answer.accounts.includes(card.accountId)) { answer.accounts.push(card.accountId) }

            if (getters.getLimitNumber('maxBanks') && !getters.accounts[card.accountId].bankId) { answer.showNoBank = true }

            const currencyCode = getters.accounts[card.accountId].currencyCode
            if (currencyCode && !answer.currencies.includes(currencyCode)) {
              answer.currencies.push(currencyCode)
            }
          }
        }
      }

      return answer
    }
  }
}